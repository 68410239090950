<template>
  <div class="px-7 py-5 grid gap-y-8">
    <nav
      class="isolate flex divide-x divide-selected border border-selected rounded-2xl shadow md:w-1/3 grid grid-cols-3"
      aria-label="Tabs"
    >
      <a
        v-for="(challenge, index) in challenges"
        :key="challenge"
        @click="$emit('setSelectedChallenge', challenge)"
        :class="{
          'bg-selected font-medium': selectedChallenge === challenge,
          'text-white bg-default': selectedChallenge !== challenge,
          'rounded-l-2xl': index === 0,
          'rounded-r-2xl': index === challenges.length - 1,
        }"
        class="cursor-pointer text-white group font-light relative min-w-0 sm:flex-1 overflow-hidden py-4 px-2 md:px-4 text-center text-sm hover:bg-selected focus:z-10"
        aria-current="page"
      >
        <span class="uppercase"
          >{{
            challenge !== "all"
              ? challenge === "2402"
                ? "February 2024"
                : "March 2024"
              : "all"
          }}
        </span>
      </a>
    </nav>
  </div>
</template>

<script>
export default {
  name: "CompetitionDateTab",
  props: {
    selectedChallenge: {
      type: String,
      default: "all",
    },
  },
  data() {
    return {
      challenges: ["all", "2402", "2403"],
    };
  },
};
</script>

<style></style>
