<script setup>
import { Vue3Lottie } from "vue3-lottie";
import leaderMotionJson from "@/assets/lottiefiles/leaderboard-motion.json";
import { defineProps, ref, onUpdated, defineExpose } from "vue";

const props = defineProps({
  width: {
    type: String,
    default: "auto",
  },
  height: {
    type: String,
    default: "20rem",
  },
  speed: {
    type: Number,
    default: 1,
  },
  loop: {
    type: Boolean,
    default: true,
  },
  ranks: {
    type: Object,
  },
});

const leaderboard = ref(null);

const updateRanking = () => {
  /**
   * Rank - First
   */
  console.log("Updating the leaderboard...");
  if (props.ranks._1st) {
    leaderboard.value.updateDocumentData({ t: props.ranks._1st.name }, 1);
    leaderboard.value.updateDocumentData(
      {
        t: props.ranks._1st.percentage.toFixed(0).toString() + "%",
      },
      0
    );
  }

  /**
   * Rank - Second
   */
  if (props.ranks._2nd) {
    leaderboard.value.updateDocumentData({ t: props.ranks._2nd.name }, 3);
    leaderboard.value.updateDocumentData(
      {
        t: props.ranks._2nd.percentage.toFixed(0).toString() + "%",
      },
      2
    );
  }

  /**
   * Rank - Third
   */
  if (props.ranks._3rd) {
    leaderboard.value.updateDocumentData({ t: props.ranks._3rd.name }, 6);
    leaderboard.value.updateDocumentData(
      {
        t: props.ranks._3rd.percentage.toFixed(0).toString() + "%",
      },
      4
    );
  }
};

const restart = () => {
  leaderboard.value.goToAndPlay(0, true);
};

onUpdated(() => {
  for (let i = 1; i <= 3; i++) {
    const rankName = i === 0 ? "1st" : i === 1 ? "2nd" : "3rd";
    const rank = props.ranks["_" + rankName];
    if (rank !== null && rank !== undefined) {
      updateRanking();
    }
  }
});

defineExpose({
  restart,
});
</script>

<template>
  <div>
    <Vue3Lottie
      class="banner"
      :animationData="leaderMotionJson"
      :width="props.width"
      :height="props.height"
      :speed="props.speed"
      :loop="props.loop"
      ref="leaderboard"
    />
  </div>
</template>
<style lang="postcss" scoped>
.banner {
  @apply bg-black mx-8 rounded-2xl w-auto relative;
}
/deep/div.lottie-animation-container svg {
  height: -webkit-fill-available !important;
  min-height: 150px;
  @apply absolute left-0 top-0;
}
</style>
