<template>
  <div class="px-7 py-5 grid gap-y-8">
    <div
      class="sm:px-4 sm:px-6 lg:px-8 sm:pt-8 pt-4 pb-4 mt-15 border border-selected rounded-2xl shadow bg-gradient-to-b from-card-t to-card-b overflow-scroll sm:overflow-auto"
    >
      <div
        class="sm:flex sm:items-center border-b border-selected pb-4 sm:pb-8 text-white px-4 sm:px-0"
      >
        <div class="sm:flex-auto">
          <h1 class="text-base font-light leading-6 text-white text-lg">
            TOP {{ topN }} LEADERS
          </h1>
        </div>
      </div>
      <div class="sm:mt-5 flow-root">
        <div class="-mx-4 sm:-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div
            class="inline-block min-w-full sm:py-2 align-middle sm:px-6 lg:px-8 px-4"
          >
            <table class="min-w-full">
              <thead
                class="text-xs font-medium uppercase tracking-wide text-gray-500 hidden sm:table-header-group"
              >
                <tr>
                  <th scope="col" class="py-3 pl-4 pr-3 text-left text-xs">
                    Place
                  </th>
                  <th scope="col" class="px-3 py-3 text-left">Name</th>
                  <th scope="col" class="px-3 py-3 text-left">Account Size</th>
                  <th scope="col" class="px-3 py-3 text-left">Gain %</th>
                  <th scope="col" class="px-3 py-3 text-left">Equity</th>
                  <th scope="col" class="px-3 py-3 text-left">Profit</th>
                  <th scope="col" class="px-3 py-3 text-left">Country</th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-700 text-sm text-white">
                <tr
                  v-for="(leader, index) in leaders"
                  :key="index"
                  :class="{
                    'bg-gradient-to-r from-top-tr-l to-top-tr-r':
                      [0, 1, 2].includes(index) &&
                      !(
                        currentUser.length != 0 &&
                        currentUser[0].id === leader.id
                      ),
                    'bg-gradient-to-r from-last-tr-l to-last-tr-r font-bold':
                      currentUser.length != 0 &&
                      currentUser[0].id === leader.id,
                  }"
                >
                  <td class="whitespace-nowrap sm:hidden py-6 px-4">
                    <dl class="">
                      <div class="grid grid-cols-2 gap-4">
                        <dt class="text-sm uppercase text-gray-400">
                          Position
                        </dt>
                        <dd
                          class="mt-1 text-sm leading-6 sm:col-span-2 sm:mt-0"
                        >
                          #{{ index + 1 }}
                        </dd>
                      </div>
                      <div class="grid grid-cols-2 gap-4">
                        <dt class="text-sm uppercase text-gray-400">Name</dt>
                        <dd
                          class="mt-1 text-sm leading-6 sm:col-span-2 sm:mt-0"
                        >
                          {{ leader.nn }}
                        </dd>
                      </div>
                      <div class="grid grid-cols-2 gap-4">
                        <dt class="text-sm uppercase text-gray-400">
                          Account Size
                        </dt>
                        <dd
                          class="mt-1 text-sm leading-6 sm:col-span-2 sm:mt-0"
                        >
                          {{ leader.type.split("-")[0] }}
                        </dd>
                      </div>
                      <div class="grid grid-cols-2 gap-4">
                        <dt class="text-sm uppercase text-gray-400">Gain %</dt>
                        <dd
                          class="mt-1 text-sm leading-6 sm:col-span-2 sm:mt-0"
                        >
                          {{
                            leader.percentReturn
                              ? leader[sortBy].toFixed(2) + "%"
                              : "-"
                          }}
                        </dd>
                      </div>
                      <div class="grid grid-cols-2 gap-4">
                        <dt class="text-sm uppercase text-gray-400">Equity</dt>
                        <dd
                          class="mt-1 text-sm leading-6 sm:col-span-2 sm:mt-0"
                        >
                          {{ formatCurrency(leader.dc, leader.eq) }}
                        </dd>
                      </div>
                      <div class="grid grid-cols-2 gap-4">
                        <dt class="text-sm uppercase text-gray-400">Profit</dt>
                        <dd
                          class="mt-1 text-sm leading-6 sm:col-span-2 sm:mt-0"
                          :class="leader.newProfitColor"
                        >
                          {{ formatCurrency(leader.dc, leader.profit) }}
                        </dd>
                      </div>
                      <div class="grid grid-cols-2 gap-4">
                        <dt class="text-sm uppercase text-gray-400">Country</dt>
                        <dd
                          class="mt-1 text-sm leading-6 sm:col-span-2 sm:mt-0"
                        >
                          <img
                            :src="getCountryFlag(leader.cy)"
                            width="25"
                            v-if="leader.cy"
                          />
                          <span v-else>-</span>
                        </dd>
                      </div>
                    </dl>
                  </td>
                  <td
                    class="whitespace-nowrap py-4 pl-4 pr-3 hidden sm:table-cell"
                  >
                    #{{ index + 1 }}
                  </td>
                  <td
                    class="whitespace-nowrap py-4 pl-4 pr-3 hidden sm:table-cell"
                  >
                    {{ leader.nn }}
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 hidden sm:table-cell">
                    {{ leader.type.split("-")[0] }}
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 hidden sm:table-cell">
                    {{
                      leader.percentReturn
                        ? leader[sortBy].toFixed(2) + "%"
                        : "-"
                    }}
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 hidden sm:table-cell">
                    {{ formatCurrency(leader.dc, leader.eq) }}
                  </td>
                  <td
                    class="whitespace-nowrap px-3 py-4 hidden sm:table-cell"
                    :class="leader.newProfitColor"
                  >
                    {{ formatCurrency(leader.dc, leader.profit) }}
                  </td>
                  <td
                    class="whitespace-nowrap py-4 pl-3 pr-4 uppercase hidden sm:table-cell"
                  >
                    <img
                      :src="getCountryFlag(leader.cy)"
                      width="25"
                      v-if="leader.cy"
                    />
                    <span v-else>-</span>
                  </td>
                </tr>

                <tr
                  class="bg-gradient-to-r from-last-tr-l to-last-tr-r font-bold"
                  v-if="
                    isInTopLeaders === false &&
                    currentUser &&
                    currentUser.length != 0 &&
                    selectedChallenge === 'all'
                  "
                >
                  <td class="whitespace-nowrap sm:hidden py-6 px-4">
                    <dl class="">
                      <div class="grid grid-cols-2 gap-4">
                        <dt class="text-sm uppercase text-gray-400">
                          Position
                        </dt>
                        <dd
                          class="mt-1 text-sm leading-6 sm:col-span-2 sm:mt-0"
                        >
                          #{{ currentUser[0].place ?? currentUser[0].id }}
                        </dd>
                      </div>
                      <div class="grid grid-cols-2 gap-4">
                        <dt class="text-sm uppercase text-gray-400">Name</dt>
                        <dd
                          class="mt-1 text-sm leading-6 sm:col-span-2 sm:mt-0"
                        >
                          {{ currentUser[0].nn }}
                        </dd>
                      </div>
                      <div class="grid grid-cols-2 gap-4">
                        <dt class="text-sm uppercase text-gray-400">
                          Account Size
                        </dt>
                        <dd
                          class="mt-1 text-sm leading-6 sm:col-span-2 sm:mt-0"
                        >
                          {{ currentUser[0].type.split("-")[0] }}
                        </dd>
                      </div>
                      <div class="grid grid-cols-2 gap-4">
                        <dt class="text-sm uppercase text-gray-400">Gain %</dt>
                        <dd
                          class="mt-1 text-sm leading-6 sm:col-span-2 sm:mt-0"
                        >
                          {{
                            currentUser[0].percentReturn
                              ? currentUser[0][sortBy].toFixed(2) + "%"
                              : "-"
                          }}
                        </dd>
                      </div>
                      <div class="grid grid-cols-2 gap-4">
                        <dt class="text-sm uppercase text-gray-400">Equity</dt>
                        <dd
                          class="mt-1 text-sm leading-6 sm:col-span-2 sm:mt-0"
                        >
                          {{
                            formatCurrency(currentUser[0].dc, currentUser[0].eq)
                          }}
                        </dd>
                      </div>
                      <div class="grid grid-cols-2 gap-4">
                        <dt class="text-sm uppercase text-gray-400">Profit</dt>
                        <dd
                          class="mt-1 text-sm leading-6 sm:col-span-2 sm:mt-0"
                          :class="currentUser[0].newProfitColor"
                        >
                          {{
                            formatCurrency(
                              currentUser[0].dc,
                              currentUser[0].profit
                            )
                          }}
                        </dd>
                      </div>
                      <div class="grid grid-cols-2 gap-4">
                        <dt class="text-sm uppercase text-gray-400">Country</dt>
                        <dd
                          class="mt-1 text-sm leading-6 sm:col-span-2 sm:mt-0"
                        >
                          <img
                            :src="getCountryFlag(currentUser[0].cy)"
                            width="25"
                            v-if="currentUser[0].cy"
                          />
                          <span v-else>-</span>
                        </dd>
                      </div>
                    </dl>
                  </td>
                  <td
                    class="whitespace-nowrap py-4 pl-4 pr-3 hidden sm:table-cell"
                  >
                    #{{ currentUser[0].place ?? currentUser[0].id }}
                  </td>
                  <td
                    class="whitespace-nowrap py-4 pl-4 pr-3 hidden sm:table-cell"
                  >
                    {{ currentUser[0].nn }}
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 hidden sm:table-cell">
                    {{
                      currentUser[0].type
                        ? currentUser[0].type.split("-")[0]
                        : "-"
                    }}
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 hidden sm:table-cell">
                    {{
                      currentUser[0].percentReturn
                        ? currentUser[0][sortBy].toFixed(2) + "%"
                        : "-"
                    }}
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 hidden sm:table-cell">
                    {{
                      currentUser[0].dc && currentUser[0].eq
                        ? formatCurrency(currentUser[0].dc, currentUser[0].eq)
                        : "-"
                    }}
                  </td>
                  <td
                    class="whitespace-nowrap px-3 py-4 hidden sm:table-cell"
                    :class="currentUser[0].newProfitColor"
                  >
                    {{
                      currentUser[0].dc && currentUser[0].profit
                        ? formatCurrency(
                            currentUser[0].dc,
                            currentUser[0].profit
                          )
                        : "-"
                    }}
                  </td>
                  <td
                    class="whitespace-nowrap py-4 pl-3 pr-4 uppercase hidden sm:table-cell"
                  >
                    <img
                      :src="getCountryFlag(currentUser[0].cy)"
                      width="25"
                      v-if="currentUser[0].cy"
                    />
                    <span v-else>-</span>
                  </td>
                </tr>
                <tr class="overflow-none" v-show="showViewMoreBtn">
                  <td colspan="2" class="px-4 sm:px-0">
                    <button
                      type="button"
                      @click="$emit('setTopN', topN + 10)"
                      class="my-4 rounded-lg bg-primary px-6 py-2 text-sm font-semibold text-white shadow-sm"
                    >
                      View more
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Datatable",

  props: {
    topN: {
      type: Number,
      default: 10,
    },
    leaders: {
      type: Array,
      required: true,
    },
    currentUser: {
      type: Array,
    },
    showViewMoreBtn: {
      type: Boolean,
      default: true,
    },
    isInTopLeaders: {
      type: Boolean,
      default: false,
    },
    sortBy: {
      type: String,
    },
    selectedChallenge: {
      type: String,
      default: "all",
    },
  },
  methods: {
    formatCurrency(currency, equity) {
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: currency,
      }).format(equity);
    },
    getCountryFlag(code) {
      return `https://flagcdn.com/96x72/${code.toLowerCase()}.png`;
    },
  },
};
</script>

<style></style>
