<template>
  <div class="py-5">
    <CompetitionDateTab
      :selectedChallenge="selectedChallenge"
      @setSelectedChallenge="setSelectedChallenge"
      v-show="showCompetitionDateTab"
    />
    <LeaderboardMotion
      v-show="showBanner"
      :ranks="ranks"
      :loop="true"
      ref="leaderboardMotion"
    />
    <Datatable
      :topN="topN"
      :leaders="leaders"
      :currentUser="currentUser"
      :isInTopLeaders="isInTopLeaders"
      :sortBy="sortBy"
      v-show="showDatatable"
      :showViewMoreBtn="showViewMoreBtn"
      :selectedChallenge="selectedChallenge"
      @setTopN="(n) => (topN = n)"
    />
  </div>
</template>
<script>
import CompetitionDateTab from "@/components/CompetitionDateTab.vue";
import Datatable from "@/components/Datatable.vue";
import LeaderboardMotion from "@/components/LeaderboardMotion.vue";

export default {
  name: "Home",
  components: {
    CompetitionDateTab,
    Datatable,
    LeaderboardMotion,
  },
  created() {
    this.ws = new WebSocket(process.env.VUE_APP_WEBSOCKET_URL);
    this.ws.addEventListener("open", this.OnSocketOpen);
    this.ws.addEventListener("message", this.OnSocketMessage);
  },
  data() {
    return {
      data: [],
      ranks: {},
      ws: null,
      topN: 10,
      sortBy: "percentReturn",
      allChallenges: [],
      currentUser: null,
      showDatatable: true,
      showCompetitionDateTab: true,
      selectedChallenge: "all",
      showViewMoreBtn: true,
      showBanner: true,
      leaders: [],
      isInTopLeaders: false,
    };
  },
  watch: {
    "$route.query": {
      deep: true,
      immediate: true,
      handler(query) {
        if (decodeURIComponent(query.cu) !== undefined) {
          this.getCurrentUser();
        }
        this.showDatatable =
          query.dt !== undefined ? JSON.parse(query.dt) : true;
        this.showCompetitionDateTab =
          query.ct !== undefined ? JSON.parse(query.ct) : true;
        this.selectedChallenge = query.act !== undefined ? query.act : "all";
        this.showViewMoreBtn =
          query.vmb !== undefined ? JSON.parse(query.vmb) : true;
        this.showBanner =
          query.ttb !== undefined ? JSON.parse(query.ttb) : true;
      },
    },
    filters: {
      immediate: true,
      handler(newVal, oldVal) {
        let filteredChallenges = [];

        //revert to 10 on change of selected challenge
        if (oldVal != undefined && oldVal[1] !== newVal[1]) {
          this.topN = 10;
        }

        //sort based on selected challenge
        if (this.selectedChallenge !== "all") {
          filteredChallenges = this.filterChallengeBytype();
          this.sortChallengesByGain(filteredChallenges);
        } else {
          this.sortChallengesByGain(this.allChallenges);
        }

        //get current user if with cu
        if (decodeURIComponent(this.$route.query.cu) !== undefined) {
          this.getCurrentUser();
        }
      },
    },
    leaders: {
      immediate: true,
      handler(leaders) {
        if (leaders.length > 0) {
          for (let i = 0; i < 3; i++) {
            const rankName = i === 0 ? "1st" : i === 1 ? "2nd" : "3rd";
            this.ranks["_" + rankName] = {
              name: leaders[i].nn,
              percentage: leaders[i][this.sortBy],
              rank: rankName,
            };
          }
        }
      },
    },
  },
  computed: {
    filters() {
      return [this.topN, this.selectedChallenge];
    },
  },
  methods: {
    OnSocketOpen() {
      this.ws.send(
        JSON.stringify({
          username: process.env.VUE_APP_WEBSOCKET_USERNAME,
          password: process.env.VUE_APP_WEBSOCKET_PW,
        })
      );
    },
    OnSocketMessage(event) {
      var obj = JSON.parse(event.data);

      if (obj.ping) {
        this.ws.send(JSON.stringify({ ping: true }));
        return;
      }

      if (obj.requestId === "login") {
        if (!obj.success) {
          alert("Invalid observer password!");
        } else {
          //get all challenges on load
          obj.challenges.forEach((challenge) => {
            challenge[this.sortBy] =
              ((challenge.eq - challenge.sf) / challenge.sf) * 100;
            challenge["profit"] = challenge.eq - challenge.sf;
          });

          this.allChallenges = obj.challenges;
          this.allChallenges = this.filterChallengeByCompetitionMonth();
          this.sortChallengesByGain(this.allChallenges);
          if (decodeURIComponent(this.$route.query.cu) !== undefined) {
            this.getCurrentUser();
          }
        }
      } else {
        switch (obj.x) {
          case "c": {
            //insert new challenge w/0 sorting
            let newC = obj;
            newC[this.sortBy] = ((newC.eq - newC.sf) / newC.sf) * 100;
            newC["profit"] = newC.eq - newC.sf;
            this.allChallenges.push(newC);
            break;
          }
          case "m": {
            let challenge =
              this.allChallenges.find((i) => i.id == obj.id) ?? null;
            if (challenge !== null) {
              // compute new values for eq, gain and profit
              challenge["eq"] = obj.eq;
              let newGain =
                ((challenge.eq - challenge.sf) / challenge.sf) * 100;
              let newProfit = challenge["eq"] - challenge.sf;
              challenge[this.sortBy] = newGain;

              //handle color change based on profit
              if (challenge["profit"] > newProfit) {
                challenge["newProfitColor"] = "text-red-400";
              } else {
                challenge["newProfitColor"] = "text-green-400";
              }
              challenge["profit"] = newProfit;
            }

            if (this.topN < 100) {
              let tops = [];
              if (this.selectedChallenge !== "all") {
                tops = this.allChallenges
                  .filter((challenge) => {
                    return (
                      challenge.type.split("-")[1] === this.selectedChallenge
                    );
                  })
                  .slice(0, this.topN + 2);
              } else {
                tops = this.allChallenges.slice(0, this.topN + 2);
              }

              var leaderItem = tops.find((i) => i.id == challenge.id) ?? null;
              if (leaderItem !== null) {
                var cIndx = tops.indexOf(leaderItem);

                //compare the values on upper and lower row before sorting
                if (cIndx <= this.topN) {
                  if (
                    leaderItem[this.sortBy] > tops[cIndx - 1][this.sortBy] ||
                    leaderItem[this.sortBy] < tops[cIndx + 1][this.sortBy]
                  ) {
                    this.sortChallengesByGain(tops);
                  }
                }
              }
            }

            break;
          }
        }
      }
    },

    sortChallengesByGain(challenges) {
      this.leaders = challenges
        .sort((a, b) => {
          return b[this.sortBy] - a[this.sortBy];
        })
        .slice(0, this.topN);
    },
    filterChallengeBytype() {
      return this.allChallenges.filter((challenge) => {
        return challenge.type.split("-")[1] === this.selectedChallenge;
      });
    },
    filterChallengeByCompetitionMonth() {
      return this.allChallenges.filter((challenge) => {
        return (
          challenge.type === "competition-2402" ||
          challenge.type === "competition-2403"
        );
      });
    },
    getCurrentUser() {
      this.isInTopLeaders = false;

      // is current user already displayed on table
      this.currentUser = this.leaders.filter((challenge) => {
        if (challenge.nn === decodeURIComponent(this.$route.query.cu)) {
          this.isInTopLeaders = true;
          return challenge;
        }
      });

      // if not, find the cu on the whole list and get the place #
      if (!this.isInTopLeaders) {
        this.currentUser = this.allChallenges.filter((challenge, index) => {
          if (challenge.nn === decodeURIComponent(this.$route.query.cu)) {
            challenge.place = index + 1;
            return challenge;
          }
        });
      }
    },
    setSelectedChallenge(c) {
      this.selectedChallenge = c;
      this.$refs.leaderboardMotion.restart();
    },
  },
};
</script>

<style lang="scss"></style>
